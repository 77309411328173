import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { color as d3Color } from 'd3-color';
import { hintService } from '@evotempus/api';
export const IntervalSunburstDefs = (props) => {
    const parentColorWithDepth = (node) => {
        //
        // Finds the ultimate's parent colour & tracks the depth
        //
        let depth = 0;
        let p = node;
        while (p && p.depth > 1) {
            p = p.parent;
            depth++;
        }
        //
        // Gets the parent node colour then
        // darkens according to the level of depth
        //
        let c = d3Color(hintService.calcColour(node.data.name()));
        for (let i = 0; i < depth; ++i) {
            c = c.darker();
        }
        return c.toString();
    };
    /*
     * Generate a radial gradient
     *
     * Create gradient definitions for all the segments so they are coloured differently
     * using the 'color' above but also shade to white to give a sheen effect
     */
    const nodes = props.rootNode.descendants().slice(1);
    const segmentGrads = nodes.map((node) => (_jsxs("radialGradient", Object.assign({ id: 'gradient-' + node.data.id(), cx: '30%', cy: '30%', r: '75%' }, { children: [_jsx("stop", { offset: '0%', stopColor: 'white' }), _jsx("stop", { offset: '75%', stopColor: parentColorWithDepth(node) })] }), node.data.id())));
    return (_jsxs("defs", { children: [_jsxs("radialGradient", Object.assign({ id: 'parentGradient', cx: '30%', cy: '30%', r: '75%' }, { children: [_jsx("stop", { offset: '0%', stopColor: '#ffffff' }), _jsx("stop", { offset: '50%', stopColor: '#61dafb' }), _jsx("stop", { offset: '90%', stopColor: '#1a8a7c' }), _jsx("stop", { offset: '100%', stopColor: '#164d21' })] })), segmentGrads] }));
};
