import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import Pagination from 'react-pagination-js';
import { color as d3Color } from 'd3-color';
import { hintService } from '@evotempus/api';
import { Tabs } from '@evotempus/components';
import { identifier, wikiLink } from '@evotempus/utils';
export const LegendKindTabs = (props) => {
    const totalPerPage = 8;
    const [kinds, _] = useState(hintService.getKindIds());
    const [changedNodes, setChangedNodes] = useState([]);
    const [kindPages, setKindPages] = useState([]);
    const cacheActiveTab = (tabName) => {
        console.log(`Caching Active Tab: ${tabName}`);
        props.onUpdateLegend({
            activeTab: tabName,
            visible: props.legend.visible
        });
    };
    const filterCategory = (event, categoryNode) => {
        // Make a shallow copy of the keys and filtered keys
        let categoryNodes = [...props.categoryNodes];
        let changed = [...changedNodes];
        // Make a shallow copy of the symbol to mutate
        let keyIdx = categoryNodes.indexOf(categoryNode);
        let ks = Object.assign({}, categoryNodes[keyIdx]);
        // Modify the filtered property
        ks.filtered = !categoryNode.filtered;
        // Put it back into key array.
        categoryNodes[keyIdx] = ks;
        // Add / Remove from changed
        let chgIdx = changed.indexOf(categoryNode);
        if (chgIdx < 0) {
            // Not been changed before so add to changed
            changed.push(ks);
        }
        else {
            // Already in changed so being changed back
            // so remove from changed
            changed.splice(chgIdx, 1);
        }
        setChangedNodes(changed);
        props.onUpdateCategoryNodes(categoryNodes);
    };
    const applyFilter = () => {
        props.onChangedCategories(changedNodes);
    };
    /*
     * Adds a paginate element to the kind block if the number of
     * categories is larger than the {totalPerPage} constant
     */
    const paginateKindBlock = (kind, items) => {
        var _a;
        if (items.length < totalPerPage) {
            return ({ pagination: _jsx(_Fragment, {}), items: items });
        }
        const totalSize = items.length;
        const kindPage = kindPages.find((kindPage) => kindPage.kind === kind);
        const currentPage = (_a = kindPage === null || kindPage === void 0 ? void 0 : kindPage.page) !== null && _a !== void 0 ? _a : 1;
        const offset = (currentPage - 1) * totalPerPage;
        const changePageFn = (newPage) => {
            const kp = kindPages.filter((kindPage) => kindPage.kind !== kind);
            kp.push({ kind: kind, page: newPage });
            setKindPages(kp);
        };
        items = items.slice(offset, offset + totalPerPage);
        return { pagination: (_jsx(Pagination, { currentPage: currentPage, totalSize: totalSize, sizePerPage: totalPerPage, changeCurrentPage: changePageFn, theme: 'border-bottom' })), items: items };
    };
    const renderKindBlock = (kind, categoryNodes) => {
        let items = [];
        const height = (props.height * 0.5) / props.totalPerPage;
        if (categoryNodes.length === 0) {
            items.push(
            // Ignore the entryCount attribute not being a recognised property of <div>
            // @ts-ignore
            _jsx("div", Object.assign({ title: kind, entryCount: 0, className: "subject-visual-legend-paginate" }, { children: _jsx("div", Object.assign({ className: "subject-visual-legend-items" }, { children: _jsx("p", Object.assign({ className: "subject-legend-content-none-found" }, { children: "No categories" })) })) })));
        }
        else {
            for (const categoryNode of categoryNodes) {
                let symText;
                if (categoryNode.link === '') {
                    symText = (_jsx("span", Object.assign({ style: { opacity: categoryNode.filtered ? '0.2' : '1' } }, { children: categoryNode.name })));
                }
                else {
                    symText = (_jsx("a", Object.assign({ href: wikiLink + categoryNode.link, style: { opacity: categoryNode.filtered ? '0.2' : '1' }, target: "_blank", rel: "noopener noreferrer" }, { children: categoryNode.name })));
                }
                let zeroColour = d3Color(categoryNode.colour);
                zeroColour = zeroColour.brighter().brighter();
                items.push(_jsxs("li", { children: [_jsx("div", Object.assign({ style: { height: height + 'px', width: height + 'px' }, onClick: (event) => filterCategory(event, categoryNode) }, { children: _jsxs("svg", Object.assign({ height: height, width: height }, { children: [_jsx("defs", { children: _jsxs("radialGradient", Object.assign({ cx: "50%", cy: "50%", r: "85%", id: "legend-gradient-" + identifier(categoryNode.name) }, { children: [_jsx("stop", { offset: "0%", stopColor: zeroColour.formatRgb() }), _jsx("stop", { offset: "90%", stopColor: categoryNode.colour })] })) }), _jsx("rect", { x: (height - (height * 0.75)) / 2, y: (height - (height * 0.75)) / 2, width: height * 0.75, height: height * 0.75, fill: "url(#legend-gradient-" + identifier(categoryNode.name) + ")", strokeWidth: categoryNode.filtered ? '1' : '0', stroke: categoryNode.filtered ? 'black' : '', opacity: categoryNode.filtered ? '0.2' : '1' })] })) })), symText] }, kind + '-' + categoryNode.name));
            }
        }
        const p = paginateKindBlock(kind, items);
        items = p.items;
        return (
        // Ignore the entryCount attribute not being a recognised property of <div>
        // @ts-ignore
        _jsxs("div", Object.assign({ title: kind, entryCount: items.length }, { children: [p.pagination, _jsx("div", Object.assign({ className: "subject-visual-legend-items" }, { children: _jsx("ul", Object.assign({ className: "subject-visual-legend-items-inner" }, { children: items })) })), _jsx("div", Object.assign({ className: "subject-visual-legend-footer" }, { children: _jsxs("div", Object.assign({ id: "subject-visual-legend-apply", className: changedNodes.length > 0 ? 'subject-visual-legend-apply-show' : 'subject-visual-legend-apply-hide' }, { children: [_jsx("span", Object.assign({ className: "subject-visual-legend-apply-tooltip" }, { children: "Apply Filter" })), _jsx("button", { className: "subject-visual-legend-apply-btn fas fa-check-circle", onClick: (event) => applyFilter() })] })) }))] })));
    };
    // Create the paginated tabs of categories
    const renderKindTabs = () => {
        const tabs = [];
        for (const kind of kinds) {
            const kindSymbols = props.categoryNodes.filter(categoryNode => kind === categoryNode.kind);
            tabs.push(renderKindBlock(kind, kindSymbols));
        }
        return tabs;
    };
    return (_jsx(Tabs, Object.assign({ activeTab: props.legend.activeTab, onTabClicked: cacheActiveTab }, { children: renderKindTabs() })));
};
