import { createContext } from 'react';
export const SubjectVisualContext = createContext({
    width: 0,
    height: 0,
    visualData: undefined,
    onUpdateKindPage: (kind, page) => {
        /* no-op */
    },
    legend: {
        visible: false,
        activeTab: '',
    },
    setLegend: (legend) => {
        /* no-op */
    },
    setError: (error) => {
        /* no-op */
    },
    setErrorMsg: (msg) => {
        /* no-op */
    }
});
