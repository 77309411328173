import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { select as d3Select } from 'd3-selection';
import * as service from './subject-swimlane-service';
export const LanePageButtons = (props) => {
    const showHidePageUpBtn = (kind) => {
        return (kind.page <= 1) ? `pageUpBtn pageBtnHide` : 'pageUpBtn';
    };
    const showHidePageDownBtn = (kind) => {
        return (kind.page >= kind.pages) ? `pageDownBtn pageBtnHide` : 'pageDownBtn';
    };
    /*
     * Click function for page buttons
     */
    const handlePageClick = (event, kind) => {
        if (event.button > 0) {
            return;
        }
        let page = -1;
        if (event.currentTarget.id.includes("up") && kind.page > 0) {
            page = kind.page - 1;
        }
        else if (event.currentTarget.id.includes("down") && kind.page < kind.pages) {
            page = kind.page + 1;
        }
        if (page > 0)
            props.onUpdateKindPage(kind.name, page);
    };
    //
    // Mouse Over function for page buttons
    //
    const handlePageMouseOver = (event, kind) => {
        const pageBtnTooltip = d3Select('#pageBtnTooltip');
        if (!pageBtnTooltip)
            return;
        pageBtnTooltip
            .transition()
            .duration(200)
            .attr('class', 'pageBtnTooltip');
        pageBtnTooltip
            .html("Page " + kind.page + " of " + kind.pages);
    };
    //
    // Mouse Out function for page buttons
    //
    const handlePageMouseOut = (event, kind) => {
        const pageBtnTooltip = d3Select('#pageBtnTooltip');
        if (!pageBtnTooltip)
            return;
        pageBtnTooltip
            .transition()
            .duration(500)
            .attr('class', 'pageBtnTooltip pageBtnTooltipHide');
    };
    return (_jsxs(React.Fragment, { children: [_jsx("g", Object.assign({ id: 'lane-page-up-btns', clipPath: 'url(#data-clip)' }, { children: props.visualData.kinds.map((kind) => (_jsx("text", Object.assign({ id: `${kind.name}-lane-page-up-btn`, className: showHidePageUpBtn(kind), cursor: 'pointer', filter: 'url(#pgBtnBackground)', transform: service.marginTranslation(props.sysAspect), textAnchor: 'end', dx: '-25', dominantBaseline: 'hanging', x: props.sysAspect.innerWidth, y: props.yScale(kind.laneStartIdx + 1), onClick: (event) => handlePageClick(event, kind) }, { children: '\uf151' }), `lane-page-up-btns-${kind.name}`))) })), _jsx("g", Object.assign({ id: 'lane-page-down-btns', clipPath: 'url(#data-clip)' }, { children: props.visualData.kinds.map((kind) => (_jsx("text", Object.assign({ id: `${kind.name}-lane-page-down-btn`, className: showHidePageDownBtn(kind), cursor: 'pointer', filter: 'url(#pgBtnBackground)', transform: service.marginTranslation(props.sysAspect), textAnchor: 'end', dx: '-25', x: props.sysAspect.innerWidth, y: props.yScale(kind.laneStartIdx + kind.lanes - 1), onClick: (event) => handlePageClick(event, kind), onMouseOver: (event) => handlePageMouseOver(event, kind), onMouseOut: (event) => handlePageMouseOut(event, kind) }, { children: '\uf150' }), `lane-page-down-btns-${kind.name}`))) }))] }));
};
