import { createContext } from 'react';
export const AppContext = createContext({
    appWidth: -1,
    appHeight: -1,
    interval: undefined,
    setInterval: () => {
        /* no-op */
    },
    subject: undefined,
    setSubject: () => {
        /* no-op */
    },
    filteredCategories: [],
    setFilteredCategories: () => {
        /* no-op */
    }
});
