import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { fetchService } from '@evotempus/api';
import { consoleLog } from '@evotempus/utils';
import './Wiki.scss';
import { Header } from './Header';
import { Footer } from './Footer';
import { Main } from './Main';
export const Wiki = (props) => {
    const [loading, setLoading] = useState(true);
    const [linkId, setLinkId] = useState('Geologic_time_scale');
    const [description, setDescription] = useState('');
    const [error, setError] = useState();
    const [errorMsg, setErrorMsg] = useState('');
    const logErrorState = (errorMsg, error) => {
        consoleLog({ prefix: 'Wiki', message: "Error: " + errorMsg, object: error });
        setErrorMsg(errorMsg);
        setError(error);
        setLoading(false);
    };
    useEffect(() => {
        setLinkId('');
        setDescription('');
        setErrorMsg('');
        setError(undefined);
        setLoading(true);
        if (!props.topicRequest) {
            setLoading(false);
            return;
        }
        fetchService.description(props.topicRequest.type, props.topicRequest.topicTarget._id)
            .then((res) => {
            if (!res.data || res.data.length === 0) {
                logErrorState("Description cannot be displayed", new Error("No description could be loaded."));
            }
            else {
                setLoading(false);
                setLinkId(res.data.linkId);
                setDescription(res.data.description);
            }
        })
            .catch((err) => {
            logErrorState("Failed to fetch description", err);
        });
    }, [props.topicRequest]);
    if (!props.topicRequest) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs("div", Object.assign({ id: "wiki-container" }, { children: [_jsx(Header, { topicTarget: props.topicRequest.topicTarget, toggleWiki: props.toggleWiki }), _jsx(Main, { loading: loading, error: error, errorMsg: errorMsg, description: description }), _jsx(Footer, { linkId: linkId })] })));
};
