import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import './Tabs.scss';
export const Tabs = (props) => {
    var _a;
    const [activeTab, setActiveTab] = useState((_a = props.activeTab) !== null && _a !== void 0 ? _a : '');
    useEffect(() => {
        //
        // Skip to the first populated tab if activeTab is empty
        //
        if (!props.activeTab || props.activeTab.length === 0) {
            let title = props.children[0].props.title;
            for (let i = 0; i < props.children.length; ++i) {
                if (props.children[i].props.entryCount > 0) {
                    title = props.children[i].props.title;
                    break;
                }
            }
            setActiveTab(title);
        }
    }, [props.children]);
    const onClickTabItem = (tab) => {
        setActiveTab(tab);
        if (props.onTabClicked) {
            props.onTabClicked(tab);
        }
    };
    return (_jsxs("div", Object.assign({ className: 'tabs' }, { children: [_jsx("div", Object.assign({ className: 'tab-header' }, { children: _jsx("ol", Object.assign({ className: 'tab-list' }, { children: props.children.map((child, index) => {
                        const title = child.props.title;
                        const entryCount = child.props.entryCount;
                        return _jsx(Tab, { activeTab: activeTab, title: title, entryCount: entryCount, selectTab: onClickTabItem }, index);
                    }) })) })), _jsx("div", Object.assign({ className: 'tab-content' }, { children: props.children.map((child) => {
                    if (child.props.title !== activeTab) {
                        return undefined;
                    }
                    return child.props.children;
                }) }))] })));
};
export const Tab = (props) => {
    const onClick = () => {
        const { title, selectTab } = props;
        selectTab(title);
    };
    let className = 'tab-list-item';
    if (props.activeTab === props.title) {
        className += ' tab-list-active';
    }
    return (_jsx("li", Object.assign({ className: className, onClick: onClick }, { children: props.title }), '{props.title}'));
};
