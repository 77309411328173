import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext, useState } from 'react';
import Pagination from 'react-pagination-js';
import { fetchService } from '@evotempus/api';
import { AppContext, ErrorMsg, Tabs } from '@evotempus/components';
import { TopicType } from '@evotempus/types';
import { isTopic, getListIcon, idToTitle, isInterval, isSubject } from '@evotempus/utils';
import './Search.scss';
import 'react-pagination-js/dist/styles.css'; // import css
export const Search = () => {
    const { setInterval, setSubject } = useContext(AppContext);
    const [searchTerm, setSearchTerm] = useState('');
    const [msg, setMessage] = useState('');
    const [msgClass, setMessageClass] = useState('');
    const [error, setError] = useState(undefined);
    const [intervalPage, setIntervalPage] = useState(1);
    const [subjectPage, setSubjectPage] = useState(1);
    const [topicPage, setTopicPage] = useState(1);
    const [results, setResults] = useState({
        intervals: [],
        subjects: [],
        topics: [],
    });
    const [resultsClass, setResultsClass] = useState('search-results-hide');
    const totalPerPage = 10;
    const pageFn = {
        interval: (newPage) => {
            setIntervalPage(newPage);
        },
        subject: (newPage) => {
            setSubjectPage(newPage);
        },
        topic: (newPage) => {
            setTopicPage(newPage);
        },
    };
    const handleSearch = (event) => {
        if (!searchTerm) {
            setError(undefined);
            return;
        }
        setResultsClass('search-results-waiting');
        setError(undefined);
        //
        // Search the backend service
        //
        fetchService
            .search(searchTerm)
            .then((res) => {
            if (!res.data) {
                setMessage('No results found');
                setResultsClass('search-results-show');
            }
            else {
                setMessage(resultsMsg(res.data.intervals, res.data.subjects, res.data.topics));
                setMessageClass('search-msg-info');
                setIntervalPage(1);
                setSubjectPage(1);
                setTopicPage(1);
                setResults({
                    intervals: res.data.intervals,
                    subjects: res.data.subjects,
                    topics: res.data.topics,
                });
                setResultsClass('search-results-show');
            }
        })
            .catch((err) => {
            setMessage('An error occurred whilst searching');
            setMessageClass('search-msg-error');
            setError(err);
            setResultsClass('search-results-show');
        });
        event.preventDefault();
    };
    const resultsMsg = (intervals, subjects, topics) => {
        const total = (intervals ? intervals.length : 0) + (subjects ? subjects.length : 0) + (topics ? topics.length : 0);
        return 'Found ' + total + ' results';
    };
    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    };
    const closeSearchResults = () => {
        setResultsClass('search-results-hide');
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const handleNavigate = (target, event) => {
        if (event) {
            event.preventDefault();
        }
        closeSearchResults();
        setMessage(resultsMsg(results.intervals, results.subjects, results.topics));
        if (isInterval(target)) {
            setInterval(target);
        }
        else if (isSubject(target)) {
            const subject = target;
            fetchService
                .intervalEncloses(subject.from, subject.to)
                .then((res) => {
                if (!res.data || res.data.length === 0) {
                    setMessage('Error: Cannot navigate to a parent interval of the subject');
                }
                else {
                    //
                    // Selected the returned interval
                    //
                    setInterval(res.data[0]);
                    setSubject(subject);
                }
            })
                .catch((err) => {
                setMessage('An error occurred whilst trying to navigate to subject');
                setMessageClass('search-msg-error');
                setError(err);
            });
        }
        else if (isTopic(target)) {
            const topic = target;
            if (topic.topicTarget === TopicType.subject) {
                fetchService
                    .subjectById(topic.topic)
                    .then((res) => {
                    if (!res.data) {
                        setMessage('Error: Cannot navigate to a parent subject of the description');
                    }
                    else {
                        //
                        // Navigate to the returned interval
                        //
                        const subject = res.data;
                        subject.fieldType = 'subject';
                        handleNavigate(subject);
                    }
                })
                    .catch((err) => {
                    setMessage('An error occurred whilst trying to navigate to description');
                    setMessageClass('search-msg-info');
                    setError(err);
                });
            }
            else if (topic.topicTarget === TopicType.interval) {
                fetchService
                    .intervalById(topic.topic)
                    .then((res) => {
                    if (!res.data) {
                        setMessage('Error: Cannot navigate to a parent interval of the description');
                    }
                    else {
                        //
                        // Navigate to the returned interval
                        //
                        const interval = res.data;
                        interval.fieldType = 'interval';
                        handleNavigate(interval);
                    }
                })
                    .catch((err) => {
                    setMessage('An error occurred whilst trying to navigate to description');
                    setMessageClass('search-msg-error');
                    setError(err);
                });
            }
            else {
                setMessage('Cannot navigate to unknown result');
                setMessageClass('search-msg-error');
            }
        }
    };
    const hasResults = () => {
        return results.intervals.length > 0 || results.subjects.length > 0 || results.topics.length > 0;
    };
    const resultBlock = (title, currentPage, changePageFn, results) => {
        const myClass = 'search-results-content-' + title.toLowerCase();
        let items = [];
        if (results.length === 0) {
            items.push(_jsx("li", Object.assign({ style: { listStyle: 'none', listStyleImage: 'none', fontWeight: 'bold' } }, { children: _jsx("p", Object.assign({ className: 'search-results-content-none-found' }, { children: "No results found for this category." })) }), 'no-results-1'));
        }
        else {
            for (const value of results.values()) {
                const styleImage = 'url(' + getListIcon(value) + ')';
                const label = isTopic(value) ? value.topic : value.name;
                items.push(_jsx("li", Object.assign({ style: { listStyleImage: styleImage } }, { children: _jsx("button", Object.assign({ className: 'link-button', onClick: handleNavigate.bind(this, value) }, { children: idToTitle(label) })) }), value._id));
            }
        }
        let paginate = _jsx(_Fragment, {});
        if (items.length > totalPerPage) {
            const offset = (currentPage - 1) * totalPerPage;
            items = items.slice(offset, offset + totalPerPage);
            paginate = (_jsx(Pagination, { currentPage: currentPage, totalSize: results.length, sizePerPage: totalPerPage, changeCurrentPage: changePageFn, theme: 'border-bottom' }));
        }
        return (
        // Ignore the entryCount attribute not being a recognised property of <div>
        // @ts-ignore
        _jsxs("div", Object.assign({ title: title, entryCount: results.length, className: myClass }, { children: [paginate, _jsx("ul", Object.assign({ className: 'search-results-content-items' }, { children: items }))] })));
    };
    const searchBox = (_jsx("div", Object.assign({ className: 'search-box' }, { children: _jsxs("form", Object.assign({ className: 'search-form form-inline', onSubmit: handleSearch }, { children: [_jsx("input", { className: 'form-control search-term', type: 'search', placeholder: 'Search', "aria-label": 'Search', value: searchTerm, onChange: handleChange }), _jsx("button", { className: 'fas fa-search search-button', type: 'submit' })] })) })));
    const closeButton = (_jsx("div", { children: _jsx("button", { className: 'search-results-closebtn fas fa-times', onClick: closeSearchResults }) }));
    if (error) {
        return (_jsxs("div", Object.assign({ className: 'evo-search' }, { children: [searchBox, _jsx("div", Object.assign({ className: 'search-results ' + resultsClass }, { children: _jsxs("div", Object.assign({ className: 'search-results-inner' }, { children: [closeButton, _jsx("div", Object.assign({ className: 'search-results-content' }, { children: _jsx(ErrorMsg, { error: error, errorMsg: msg }) }))] })) }))] })));
    }
    let resultsTabs = _jsx(_Fragment, {});
    //
    // By checking the results class, this ensures that the tabs are unmounted
    // when the results window is closed thereby ensuring that the tabs.useEffects for
    // selecting the activeTab is fired the next time a search is triggered.
    // Otherwise, running 1 search followed by another only triggers the activeTab select
    // after the first search but not the second. Making the useEffect more powerful by
    // using a props var or other value causes problems when selecting other tabs or the
    // content paging numbers.
    //
    if (hasResults() && resultsClass === 'search-results-show') {
        resultsTabs = (_jsxs(Tabs, { children: [resultBlock('Geological Intervals (' + results.intervals.length + ')', intervalPage, pageFn.interval, results.intervals), resultBlock('Historical Subjects (' + results.subjects.length + ')', subjectPage, pageFn.subject, results.subjects), resultBlock('Descriptions (' + results.topics.length + ')', topicPage, pageFn.topic, results.topics)] }));
    }
    return (_jsxs("div", Object.assign({ className: 'evo-search' }, { children: [searchBox, _jsx("div", Object.assign({ className: 'search-results ' + resultsClass }, { children: _jsxs("div", Object.assign({ className: 'search-results-inner' }, { children: [closeButton, _jsxs("div", Object.assign({ className: 'search-results-content' }, { children: [_jsx("p", Object.assign({ className: msgClass }, { children: msg })), resultsTabs] }))] })) }))] })));
};
