import ColorHash from 'color-hash';
export class HintService {
    constructor() {
        this.hints = {};
        this.colorHash = new ColorHash();
    }
    setHints(hintArr) {
        if (!hintArr) {
            throw new Error('Hints passed to hint service is null');
        }
        for (const h of hintArr.values()) {
            this.hints[h._id] = {
                colour: h.colour,
                link: h.link,
                order: h.order,
                type: h.type,
                parent: h.parent,
            };
        }
    }
    length() {
        return Object.entries(this.hints).length;
    }
    getKindIds() {
        const ids = [];
        Object.entries(this.hints).forEach(([k, v]) => {
            if (v.type === 'Kind') {
                ids.push(k);
            }
        });
        return ids;
    }
    getCategoryIds() {
        const ids = [];
        Object.entries(this.hints).forEach(([k, v]) => {
            if (v.type === 'Category') {
                ids.push(k);
            }
        });
        return ids;
    }
    getTagIds() {
        const ids = [];
        Object.entries(this.hints).forEach(([k, v]) => {
            if (v.type === 'Tag') {
                ids.push(k);
            }
        });
        return ids;
    }
    getHint(name) {
        const hint = this.hints[name];
        if (!hint) {
            throw new Error('No hint in hint service with name ' + name);
        }
        return hint;
    }
    calcColour(name) {
        const hint = this.hints[name];
        if ((hint === null || hint === void 0 ? void 0 : hint.colour) && (hint === null || hint === void 0 ? void 0 : hint.colour.length) > 0) {
            return hint.colour;
        }
        return this.colorHash.hex(name);
    }
    calcColours(names) {
        const colours = [];
        for (const name of names.values()) {
            colours.push(this.calcColour(name));
        }
        return colours;
    }
}
export const hintService = new HintService();
