import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import "react-pagination-js/dist/styles.css"; // import css
import './SubjectVisualLegend.scss';
import { initCategoryNodes } from './subject-visual-legend-service';
import { LegendKindTabs } from './LegendKindTabs';
export const SubjectVisualLegend = (props) => {
    const [categoryNodes, setCategoryNodes] = useState(initCategoryNodes(props.displayedCategoryNames, props.categories));
    const [totalPerPage] = useState(Math.min(10, (props.height * 0.5) / 40));
    const [activeTab] = useState(props.legend.activeTab);
    const close = () => {
        props.onUpdateLegend({
            activeTab: activeTab,
            visible: false
        });
    };
    const onChangedCategories = (changedCategories) => {
        const categories = [...props.categories];
        for (let i = 0; i < changedCategories.length; ++i) {
            const cc = changedCategories[i];
            const c = categories.find(category => category.name === cc.name);
            if (!c)
                continue;
            c.filtered = cc.filtered;
        }
        props.onUpdateCategories(categories);
    };
    return (_jsx("div", Object.assign({ id: "subject-visual-legend", className: props.legend.visible ? 'show' : 'hide' }, { children: _jsxs("div", Object.assign({ className: "subject-visual-legend-content" }, { children: [_jsx("div", Object.assign({ className: "subject-visual-legend-title-row-1" }, { children: _jsx("button", { className: "subject-visual-legend-closebtn fas fa-times", onClick: close }) })), _jsx("div", Object.assign({ className: "subject-visual-legend-title-row-2" }, { children: _jsx("div", Object.assign({ className: "subject-visual-legend-text" }, { children: _jsxs("p", { children: ["Select icons to filter then click", _jsx("i", { className: "subject-visual-legend-text-apply fas fa-check-circle" })] }) })) })), _jsx("div", Object.assign({ className: "subject-visual-legend-kinds" }, { children: _jsx(LegendKindTabs, { height: props.height, categoryNodes: categoryNodes, totalPerPage: totalPerPage, legend: props.legend, onUpdateLegend: props.onUpdateLegend, onUpdateCategoryNodes: setCategoryNodes, onChangedCategories: onChangedCategories }) }))] })) })));
};
