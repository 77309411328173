import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Loading } from '@evotempus/layout';
import { fetchService } from '@evotempus/api';
import { consoleLog } from '@evotempus/utils';
import { ErrorMsg } from '../ErrorMsg';
import { IntervalSunburst } from './IntervalSunburst';
import './IntervalVisual.scss';
let intervalsInit = false;
export const IntervalVisual = () => {
    const [errorMsg, setErrorMsg] = useState();
    const [error, setError] = useState();
    const [loading, setLoading] = useState(true);
    const [visualIntervals, setVisualIntervals] = useState([]);
    const logErrorState = (errorMsg, error) => {
        consoleLog({ prefix: 'Error', message: errorMsg + '\nDetail: ', object: error });
        setErrorMsg(errorMsg);
        setError(error);
        setLoading(false);
    };
    useEffect(() => {
        if (intervalsInit)
            return;
        intervalsInit = true;
        // Fetch the interval data from the backend service
        fetchService
            .intervals()
            .then((res) => {
            if (!res.data || res.data.length === 0) {
                logErrorState('Data failed to be fetched', new Error('Response data payload was empty.'));
            }
            else {
                setLoading(false);
                setVisualIntervals(res.data);
            }
        })
            .catch((err) => {
            logErrorState('Failed to fetch interval data', err);
        });
    }, []);
    if (loading) {
        return (_jsx("div", Object.assign({ className: 'interval-visual-loading' }, { children: _jsx(Loading, {}) })));
    }
    if (error) {
        return _jsx(ErrorMsg, { error: error, errorMsg: errorMsg });
    }
    return (_jsx(IntervalSunburst, { visualIntervals: visualIntervals }));
};
