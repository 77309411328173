import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { identifier } from "@evotempus/utils";
import * as service from './subject-swimlane-service';
export const Lanes = (props) => {
    const calcLaneNameY = (kind) => {
        const yn = props.yScale(kind.laneStartIdx + (kind.lanes / 2)) + 0.5;
        const fontHeight = 30;
        return yn + fontHeight;
    };
    return (_jsxs(React.Fragment, { children: [_jsx("g", Object.assign({ id: 'lane-lines', clipPath: 'url(#data-clip)' }, { children: props.visualData.lanes.map((lane) => {
                    var _a, _b, _c, _d, _e, _f;
                    return (_jsx("line", { id: `${(_a = lane.meta) === null || _a === void 0 ? void 0 : _a.id}`, className: 'laneLines', transform: service.marginTranslation(props.sysAspect), stroke: ((_b = lane.meta) === null || _b === void 0 ? void 0 : _b.kind.lane) ? 'black' : 'lightgray', strokeWidth: ((_c = lane.meta) === null || _c === void 0 ? void 0 : _c.kind.lane) ? 5 : 1, x1: '10', y1: (props.yScale(lane.meta ? ((_d = lane.meta) === null || _d === void 0 ? void 0 : _d.id) : 0) + 0.5), x2: props.sysAspect.innerWidth, y2: (props.yScale(lane.meta ? ((_e = lane.meta) === null || _e === void 0 ? void 0 : _e.id) : 0) + 0.5) }, `lane-lines-${(_f = lane.meta) === null || _f === void 0 ? void 0 : _f.id}`));
                }) })), _jsx("g", Object.assign({ id: 'lane-backgrounds', clipPath: 'url(#data-clip)' }, { children: props.visualData.kinds.map((kind) => (_jsx("rect", { className: 'laneBackground', transform: service.marginTranslation(props.sysAspect), fill: `url(#gradient-${identifier(kind.name)})`, x: '0', y: (props.yScale(kind.laneStartIdx) + 0.5), width: props.sysAspect.innerWidth, height: (props.yScale(kind.laneStartIdx + kind.lanes) + 0.5) - (props.yScale(kind.laneStartIdx) + 0.5) }, `lane-backgrounds-${kind.name}`))) })), _jsx("g", Object.assign({ id: 'lane-names', clipPath: 'url(#label-clip)' }, { children: props.visualData.kinds.map((kind) => (_jsx("text", Object.assign({ className: kind.name, textAnchor: 'end', transform: service.marginTranslation(props.sysAspect), x: '-10', y: calcLaneNameY(kind) }, { children: kind.name }), `lane-names-${kind.name}`))) }))] }));
};
