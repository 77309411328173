import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useContext, useMemo, useRef } from 'react';
import { AppContext } from '@evotempus/components/app';
import { SubjectVisualContext } from '../context';
import { SubjectVisualLegend } from './subjectvisuallegend';
import * as service from './subject-swimlane-service';
import { Definitions } from './Definitions';
import { ContainerGroup } from './ContainerGroup';
import { svgId } from './constants';
import './SubjectSwimLane.scss';
export const SubjectSwimLane = () => {
    var _a;
    const svgRef = useRef(null);
    const { interval, setInterval, subject, setSubject, filteredCategories, setFilteredCategories } = useContext(AppContext);
    const { width, height, visualData, onUpdateKindPage, legend, setLegend, setError, setErrorMsg } = useContext(SubjectVisualContext);
    /*
     * Calculates the zoom system and radius then caches unless
     * the width and height changes
     */
    const sysAspect = useMemo(() => service.calculateAspect(width, height), [width, height]);
    const handleLegendClick = (event) => {
        setLegend({
            activeTab: legend.activeTab,
            visible: !legend.visible
        });
        event.stopPropagation();
    };
    /*
     * Reset all categories back to visible
     */
    const resetCategories = (event) => {
        setFilteredCategories(filteredCategories.map(fc => {
            return { name: fc.name, filtered: false };
        }));
        if (event) {
            event.preventDefault();
        }
    };
    const svgStructure = useMemo(() => {
        if (!visualData || !interval)
            return (_jsx(_Fragment, {}));
        return (_jsxs(React.Fragment, { children: [_jsx(Definitions, { sysAspect: sysAspect, filteredCategories: filteredCategories, kinds: visualData.kinds }), _jsx(ContainerGroup, { sysAspect: sysAspect, interval: interval, visualData: visualData, onUpdateKindPage: onUpdateKindPage, setInterval: setInterval, subject: subject, setSubject: setSubject, setError: setError, setErrorMsg: setErrorMsg })] }));
    }, [sysAspect, interval, filteredCategories, subject, visualData]);
    if (!interval) {
        return (_jsx("div", Object.assign({ className: "subject-visual-component" }, { children: _jsx("div", Object.assign({ className: "subject-visual-nocontent" }, { children: _jsx("p", { children: "No geological interval. Try clicking on the Geological Timescale." }) })) })));
    }
    if (!service.hasSubjects(visualData)) {
        return (_jsx("div", Object.assign({ className: "subject-visual-component" }, { children: _jsxs("div", Object.assign({ className: "subject-visual-nocontent" }, { children: [_jsxs("p", { children: ["No content available for the ", interval.name, " ", interval.kind] }), _jsxs("p", { children: [_jsx("button", Object.assign({ className: "subject-visual-reset-button", onClick: (e) => resetCategories(e) }, { children: "Click" })), " to reset category filters"] })] })) })));
    }
    return (_jsxs("div", Object.assign({ className: "subject-visual-component" }, { children: [_jsx("div", Object.assign({ className: "subject-visual-button" }, { children: _jsx("button", { id: "subject-visual-legend-btn", className: "fas fa-bars", onClick: handleLegendClick }) })), _jsx(SubjectVisualLegend, { height: height, legend: legend, onUpdateLegend: setLegend, categories: filteredCategories, onUpdateCategories: setFilteredCategories, displayedCategoryNames: (_a = visualData === null || visualData === void 0 ? void 0 : visualData.categoryNames) !== null && _a !== void 0 ? _a : [] }), _jsx("svg", Object.assign({ ref: svgRef, id: svgId, width: width, height: height, viewBox: "0 0 " + (width * sysAspect.viewPort) + " " + (height * sysAspect.viewPort), preserveAspectRatio: "xMidYMid slice" }, { children: svgStructure })), _jsx("div", { id: 'pageBtnTooltip', className: 'pageBtnTooltip pageBtnTooltipHide' })] })));
};
