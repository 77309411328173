import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { IntervalSunburstSegmentPaths } from './IntervalSunburstSegmentPaths';
import { IntervalSunburstSegmentLabels } from './IntervalSunburstSegmentsLabels';
export const IntervalSunburstSegments = (props) => {
    const centreTranslation = useMemo(() => {
        return 'translate(' + props.zoomSystem.ox + ',' + props.zoomSystem.oy + ')';
    }, [props.zoomSystem]);
    return (_jsxs(React.Fragment, { children: [_jsx("g", Object.assign({ id: 'int-segment-container', className: 'int-segment-container', transform: centreTranslation }, { children: _jsx(IntervalSunburstSegmentPaths, { nodes: props.nodes, parent: props.parent, radius: props.radius, selected: props.selected, setSelected: props.setSelected, navigate: props.navigate }) })), _jsx("g", Object.assign({ id: 'int-label-container', className: 'int-label-container', transform: centreTranslation, pointerEvents: 'none', textAnchor: 'middle', style: { userSelect: 'none', fontWeight: 'bold' } }, { children: _jsx(IntervalSunburstSegmentLabels, { nodes: props.nodes, radius: props.radius }) }))] }));
};
