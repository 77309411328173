import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { max as d3Max, min as d3Min } from 'd3-array';
import * as service from './subject-swimlane-service';
/**
 * Create the bounding blocks of the interval limits
 */
export const IntervalBounds = (props) => {
    const xsMin = props.xScale.domain()[0];
    const xsMax = props.xScale.domain()[1];
    const ysMin = d3Min(props.visualData.lanes, d => { var _a; return (_a = d.meta) === null || _a === void 0 ? void 0 : _a.id; }); // domain can be smaller, due to nice()
    const ysMax = d3Max(props.visualData.lanes, d => { var _a; return d.meta ? (_a = d.meta) === null || _a === void 0 ? void 0 : _a.id : 0; }) + 1; // domain can be longer, due to nice()
    const fromX = props.xScale(props.interval.from);
    const toX = props.xScale(props.interval.to);
    const minX = props.xScale(xsMin);
    const maxX = props.xScale(xsMax);
    const minY = props.yScale(ysMin);
    const maxY = props.yScale(ysMax);
    return (_jsxs(React.Fragment, { children: [_jsxs("g", Object.assign({ id: 'lower-interval-bounds', clipPath: 'url(#data-clip)' }, { children: [_jsx("line", { className: 'lowerIntervalBoundLine', stroke: 'black', strokeWidth: '2', strokeDasharray: '5,5', transform: service.marginTranslation(props.sysAspect), x1: fromX, y1: minY, x2: fromX, y2: maxY }), _jsx("rect", { className: 'lowerIntervalBoundBlock', fill: 'darkgray', fillOpacity: '0.3', transform: service.marginTranslation(props.sysAspect), x: minX, y: minY, width: fromX - minX, height: maxY - minY })] })), _jsxs("g", Object.assign({ id: 'upper-interval-bounds', clipPath: 'url(#data-clip)' }, { children: [_jsx("line", { className: 'upperIntervalBoundLine', stroke: 'black', strokeWidth: '2', strokeDasharray: '5,5', transform: service.marginTranslation(props.sysAspect), x1: toX, y1: minY, x2: toX, y2: maxY }), _jsx("rect", { className: 'upperIntervalBoundBlock', fill: 'darkgray', fillOpacity: '0.3', transform: service.marginTranslation(props.sysAspect), x: toX, y: minY, width: maxX - toX, height: maxY - minY })] }))] }));
};
