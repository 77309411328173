export const svgId = 'interval-visual-component-svg';
export const ELLIPSIS = '...';
export const TEXT_LENGTH = 7;
export const clickDelay = 200;
export class Dimensions {
    constructor({ x0 = 0, x1 = 0, y0 = 0, y1 = 0 }) {
        this.x0 = 0;
        this.y0 = 0;
        this.x1 = 0;
        this.y1 = 0;
        this.x0 = x0;
        this.x1 = x1;
        this.y0 = y0;
        this.y1 = y1;
    }
    interpolateValue(current, target, step, exclusiveMax) {
        /*
         * As the max is exclusive, we need to substract 1 from it
         * to ensure the interpolate value is correctly evaluated
         */
        const stepsLeft = exclusiveMax - 1 - step;
        // If they match then no more work to be done, regardless of steps left
        if (current === target)
            return target;
        // Finds the difference, which can be -ve or +ve
        const diff = target - current;
        // Calculate the proportion of the remaining difference using the steps left
        const increment = stepsLeft > 0 ? diff / stepsLeft : diff;
        // Return the new value of current with added/subtracted extra work
        return current + increment;
    }
    /*
     * Begin to interpolate this dimension to the target dimension
     * taking into account the step of the interpolation and the exclusive
     * maximum of the steps
     */
    interpolate(target, step, exclusiveMax) {
        const v = Object.assign(this);
        v.x0 = this.interpolateValue(this.x0, target.x0, step, exclusiveMax);
        v.x1 = this.interpolateValue(this.x1, target.x1, step, exclusiveMax);
        v.y0 = this.interpolateValue(this.y0, target.y0, step, exclusiveMax);
        v.y1 = this.interpolateValue(this.y1, target.y1, step, exclusiveMax);
        return v;
    }
}
/**
 * Interval decorated with additional view properties
 */
export class ViewInterval {
    constructor(interval) {
        this.visible = false;
        this.wasVisible = false;
        this.selected = false;
        this.interval = interval;
        this.current = null;
        this.target = null;
    }
    /**
     * Don't expose the interval as it encourages mistakes
     * Instead allow direct access through a callback function
     */
    call(callback) {
        callback(this.interval);
    }
    id() {
        return this.interval._id;
    }
    name() {
        return this.interval.name;
    }
    parent() {
        return this.interval.parent;
    }
    to() {
        return this.interval.to;
    }
    from() {
        return this.interval.from;
    }
    progeny() {
        return this.interval.children.length;
    }
    owner() {
        return this.marker('owner');
    }
    setOwner(value) {
        this.addMarker('owner', value);
    }
    marker(key) {
        if (!this.markers)
            return null;
        return this.markers[key];
    }
    addMarker(key, value) {
        if (!this.markers)
            this.markers = {};
        this.markers[key] = value;
    }
}
