import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Loading } from 'src/layout';
import { ErrorMsg } from '../ErrorMsg';
export const Main = (props) => {
    const wikiDescription = () => {
        if (props.loading)
            return (_jsx(_Fragment, {}));
        if (props.error)
            return (_jsx(ErrorMsg, { error: props.error, errorMsg: props.errorMsg }));
        return (_jsx("p", { children: props.description }));
    };
    const wikiText = () => {
        return (_jsx("div", Object.assign({ id: "wiki-text", className: props.loading ? 'disappear' : 'fade-in' }, { children: wikiDescription() })));
    };
    return (_jsx(React.Fragment, { children: _jsx("div", Object.assign({ id: "wiki-main" }, { children: _jsxs("div", Object.assign({ id: "wiki-main-inner" }, { children: [props.loading && (_jsx("div", Object.assign({ id: "wiki-loading", className: "fade-in" }, { children: _jsx(Loading, {}) }))), !props.loading && (_jsx("div", { id: "wiki-loading", className: "disappear" })), wikiText()] })) })) }));
};
