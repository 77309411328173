import { instance as http } from './axios.http';
class FetchService {
    intervals() {
        return http({
            method: 'GET',
            url: '/api/intervals',
        });
    }
    intervalById(id) {
        return http({
            method: 'GET',
            url: '/api/intervals/' + id,
        });
    }
    intervalEncloses(from, to) {
        return http({
            method: 'GET',
            url: '/api/intervals',
            params: {
                from: from,
                to: to,
                limited: true, // Ensures that the single narrowest interval is returned
            },
        });
    }
    description(topicType, topicId) {
        return http({
            method: 'GET',
            url: '/api/' + topicType + 's/description/' + topicId,
        });
    }
    subjectById(id) {
        return http({
            method: 'GET',
            url: '/api/subjects/' + id,
        });
    }
    subjectsWithin(criteria) {
        return http({
            method: 'POST',
            url: '/api/subjects',
            data: {
                from: criteria.interval ? criteria.interval.from : undefined,
                to: criteria.interval ? criteria.interval.to : undefined,
                kind: criteria.kind,
                page: criteria.page,
                subject: criteria.subjectId,
                excluded: criteria.excludedCategories,
            },
        });
    }
    subjectCategories() {
        return http.get('/api/subjects/categories');
    }
    search(searchTerm) {
        return http.get('/api/search', {
            params: {
                query: searchTerm,
            },
        });
    }
    hints() {
        return http.get('/api/hints');
    }
}
export const fetchService = new FetchService();
