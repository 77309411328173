import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { color as d3Color } from 'd3-color';
import { scaleOrdinal as d3ScaleOrdinal } from 'd3-scale';
import { identifier } from '@evotempus/utils';
import { hintService } from '@evotempus/api';
export const Definitions = (props) => {
    const categoryNames = props.filteredCategories.map(a => a.name);
    const kindNames = props.kinds.map(a => a.name);
    const categoryColorCycle = d3ScaleOrdinal(categoryNames, hintService.calcColours(categoryNames));
    const laneColorCycle = d3ScaleOrdinal(kindNames, hintService.calcColours(kindNames));
    const nameColor = (name, brighter) => {
        const ordinal = categoryColorCycle(name);
        const c = d3Color(ordinal);
        return brighter ? c.brighter().brighter().toString() : c.toString();
    };
    const laneColor = (kind, brighter) => {
        const ordinal = laneColorCycle(kind);
        const c = d3Color(ordinal);
        return brighter ? c.brighter().brighter().toString() : c.toString();
    };
    /*
     * Generate radial gradients
     *
     * Create gradient definitions for all the category names so they are coloured differently
     */
    const nameGradients = categoryNames.map((name) => (_jsxs("radialGradient", Object.assign({ id: 'gradient-' + identifier(name), cx: '50%', cy: '50%', r: '85%' }, { children: [_jsx("stop", { offset: '0%', stopColor: nameColor(name, true) }), _jsx("stop", { offset: '90%', stopColor: nameColor(name, false) })] }), identifier(name))));
    const laneGradients = kindNames.map((kind) => (_jsxs("radialGradient", Object.assign({ id: 'gradient-' + identifier(kind), cx: '50%', cy: '50%', r: '85%' }, { children: [_jsx("stop", { offset: '0%', stopColor: laneColor(kind, true) }), _jsx("stop", { offset: '90%', stopColor: laneColor(kind, false) })] }), identifier(kind))));
    return (_jsxs("defs", { children: [nameGradients, laneGradients, _jsx("clipPath", Object.assign({ id: 'data-clip' }, { children: _jsx("rect", { x: props.sysAspect.margins.left, y: props.sysAspect.margins.top, width: props.sysAspect.innerWidth, height: props.sysAspect.innerHeight }) })), _jsx("clipPath", Object.assign({ id: 'label-clip' }, { children: _jsx("rect", { x: '-10', y: props.sysAspect.margins.top, width: props.sysAspect.innerWidth, height: props.sysAspect.innerHeight }) })), _jsxs("filter", Object.assign({ id: 'pgBtnBackground', x: '15%', y: '15%', width: '70%', height: '70%' }, { children: [_jsx("feFlood", { floodColor: 'white', result: 'txtBackground' }), _jsxs("feMerge", { children: [_jsx("feMergeNode", { in: 'txtBackground' }), _jsx("feMergeNode", { in: 'SourceGraphic' })] })] }))] }));
};
