import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as common from '@evotempus/utils';
import { HeaderCloseButton } from './HeaderCloseButton';
import { HeaderLogo } from './HeaderLogo';
export const Header = (props) => {
    const displayDates = () => {
        if (!props.topicTarget) {
            return '';
        }
        return "from approximately "
            + common.present(props.topicTarget.from)
            + " to " + common.present(props.topicTarget.to);
    };
    const headerTitle = () => {
        let title = '';
        let className = '';
        if (!props.topicTarget) {
            title = 'None';
            className = 'disappear';
        }
        else {
            title = props.topicTarget.name + " " + displayDates();
            className = 'fade-in';
        }
        return (_jsx("p", Object.assign({ id: "wiki-header-title", className: className }, { children: title })));
    };
    return (_jsxs("div", Object.assign({ id: "wiki-header" }, { children: [_jsx(HeaderCloseButton, { toggleWiki: props.toggleWiki }), _jsx(HeaderLogo, {}), headerTitle()] })));
};
