import { stratify as d3Stratify, partition as d3Partition } from 'd3-hierarchy';
import { ViewInterval } from './globals';
export function calculateSystemInfo(width, height) {
    const viewPort = 5;
    return {
        zoomSystem: {
            ox: (width * viewPort) / 2,
            oy: (height * viewPort) / 2,
            viewPort: viewPort
        },
        radius: Math.min(width, height) * 0.8
    };
}
/*
 * Makes a hierarchy of the json data
 * then partitions it ready for layout
 */
export function partition(data) {
    const viewData = [];
    for (const interval of data) {
        const v = new ViewInterval(interval);
        viewData.push(v);
    }
    const newRootNode = d3Stratify()
        .id((d) => d.id())
        .parentId((d) => d.parent())(viewData);
    //
    // rootNode.sum calculates the value that the node represents
    // This is essential for a partition layout since they're
    // relative areas are determined by node.value
    //
    newRootNode
        .sum((d) => {
        //
        // The computation takes this value and adds it to
        // the value of any children belonging to it. So to
        // get properly finished circles we should only consider
        // the leaf data only.
        //
        return d.progeny() === 0 ? d.to() - d.from() : 0;
    })
        .sort((a, b) => {
        //
        // Whereas sum above uses the actual data objects, sort does not;
        // it uses the HierarchyRectangularNode<ViewInterval>.
        // Therefore, we have to use ...data.from rather than ...from.
        //
        const r = a.data.from() - b.data.from();
        return r;
    });
    //
    // Effectively calling partition(rootNode)
    // Adds properties to rootNode and all its children, such as x0, y0, x1, y1
    // size() will calculate the width and height
    //
    return d3Partition().size([2 * Math.PI, newRootNode.height + 1])(newRootNode);
}
/*
 * Determines if an arc should be visible
 * based on its position in the hierarchy
 */
export function arcVisible(d) {
    return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
}
