import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo, useRef } from 'react';
import { clickDelay } from './globals';
export const IntervalSunburstParent = (props) => {
    const clickTimerRef = useRef(null);
    const clickPreventRef = useRef(false);
    const centreTranslation = useMemo(() => {
        return 'translate(' + props.zoomSystem.ox + ',' + props.zoomSystem.oy + ')';
    }, [props.zoomSystem]);
    if (!props.parent) {
        return _jsx(_Fragment, {});
    }
    const handleClick = (event, parentNode) => {
        //
        // Put inside timer to allow for double-click
        // event to determine if it should be fired
        //
        clickTimerRef.current = setTimeout(() => {
            if (clickPreventRef.current) {
                clickPreventRef.current = false;
                return;
            }
            if (!parentNode || parentNode !== props.parent || !parentNode.data.visible) {
                return;
            }
            props.setSelected(parentNode, true);
        }, clickDelay);
    };
    const handleDoubleClick = (event, parentNode) => {
        if (!parentNode)
            return;
        if (parentNode.data.progeny() === 0)
            return;
        //
        // Prevent the single click firing when
        // the user actually double-clicked. Stops
        // needless updates out of the component
        //
        if (clickTimerRef.current)
            clearTimeout(clickTimerRef.current);
        clickPreventRef.current = true;
        // Navigate to the interval
        props.navigate(parentNode);
    };
    return (_jsxs(React.Fragment, { children: [_jsx("circle", Object.assign({ id: 'parent-circle', className: props.parent.data.selected ? 'path-selected' : 'path-unselected', r: props.radius, fill: 'url(#parentGradient)', pointerEvents: 'all', style: { cursor: 'pointer' }, transform: centreTranslation, onClick: (e) => handleClick(e, props.parent), onDoubleClick: (e) => handleDoubleClick(e, props.parent) }, { children: props.parent.name }), 'parent-circle-' + props.parent.id), _jsx("text", Object.assign({ id: 'parent-label', pointerEvents: 'none', textAnchor: 'middle', style: { userSelect: 'none', fontWeight: 'bold' }, dy: '0.35em', transform: centreTranslation }, { children: props.parent.data.name() }), 'parent-text-' + props.parent.id)] }));
};
